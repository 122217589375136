import { useEffect, useMemo, useState } from 'react';
import { EventStage } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';
import { formatEther } from '@ethersproject/units';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';

import { useProjectContext } from '@src/components/Project/context';
import { useTokenClaimHasClaimed } from '@src/components/Project/Event/hooks';
import { isHardcapMet } from '@src/components/Project/Event/utils';
import { getTokenPrice } from '@src/components/Staking/Analytics/util';
import {
    BASE_IMAGE_URL,
    CONTENT,
    CONTRACT,
    DEFAULT_CHAIN_ID,
    NETWORKS,
} from '@src/config';
import { useAppSelector, useContract, useCountdown } from '@src/hooks/index';
import { useEventDates } from '@src/hooks/useEventTimes';
import { useEventToken } from '@src/hooks/useEventToken';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';
import { EventType } from '@src/ts/constants';
import { Project } from '@src/ts/interfaces';
import { generateSlug } from '@src/utils/format';
import { getEventEndDate } from '@src/utils/getters';
import { parseBalance } from '@src/utils/web3';

import {
    getChips,
    getFormattedTimeLeft,
    useCrowdfundingEventStage,
    useTokenClaimEventStage,
} from '../utils';

import {
    getCrowdfundingCompleteProps,
    getCrowdfundingGAFCFSProps,
    getCrowdfundingSnapshotProps,
    getCrowdfundingWhitelistOpenProps,
    getCrowfundingPreWhitelistProps,
} from './crowdfunding-props';
import {
    getTokenClaimCompleteProps,
    getTokenClaimPreWhitelistProps,
    getTokenClaimWhitelistOpenProps,
} from './token-claim-props';
import { useUpdateTimers } from './useUpdateTimers';

export const useMainEventCard = (project: Project) => {
    const [loading, setLoading] = useState(false);
    const [api_token_price, setApiTokenPrice] = useState(0);

    const { user } = useAppSelector((state) => state.auth);
    const router = useRouter();

    const {
        id,
        hero,
        name: project_name,
        description,
        event_status,
        social_platforms,
    } = project || {};

    const event = project?.events[0];
    const {
        start_date,
        type: event_type,
        crowdfunding_type,
        chainId,
        name: event_name,
        saft_url,
        marketing_event,
        ama_event,
        event_details: {
            durations = [],
            min_tier = 0,
            vesting = { start: '' },
        } = {},
        id: event_id,
        total_allocation,
        refund_deadline,
        token_price,
        contract,
    } = event || {};

    const {
        _userTier,
        loadProjects,
        user_status: {
            has_verified_identity,
            has_pending_verification_status,
            has_verified_wallet,
        },
    } = useGlobalContext();

    // TODO: possibly take out the fcfs whitelisted variable and let users know they can take part in fcfs
    const {
        registerForWhitelist,
        user_allocation_wei,
        num_whitelisted,
        user_allocation,
        whitelist_loading,
        ga_registered,
        crowdfunding: { user_investment, total_raised, hardcap } = {
            user_investment: '0',
            hardcap: '0',
            total_raised: '0',
        },
        finalized,
        proof,
        getWhitelistSummaryForUser,
    } = useProjectContext();

    const project_slug = generateSlug(project_name);

    const { symbol: payment_symbol, decimals: payment_decimals } =
        CONTRACT.PaymentToken[chainId || DEFAULT_CHAIN_ID];
    // TODO: how can i only call this for token claim
    const [has_claimed, legacy_alloc, update] = useTokenClaimHasClaimed(event);

    const token_claim = useContract(contract.address, contract.abi, true);

    const uses_merkle_root = !!token_claim?.setMerkleRoot;

    const alloc_to_use = uses_merkle_root
        ? BigNumber.from(user_allocation_wei)
        : legacy_alloc;

    const { account, connect, connectedChain, setChainID, settingChain } =
        useWeb3Onboard();

    const { decimals: token_decimals, symbol: token_symbol } =
        useEventToken(event);

    const is_connected_verified_wallet =
        user?.wallet_address?.toLowerCase() === account?.toLowerCase();

    // use 1 if hardcap === 0 as 0/0 === NaN
    const denom = Number(formatEther(hardcap)) || 1;

    const percent_filled = Number(
        ((Number(formatEther(total_raised)) / denom) * 100).toFixed(1),
    );

    const raised_stats = {
        total: `${parseBalance(
            total_raised,
            payment_decimals,
            2,
            true,
        )} ${payment_symbol}`,
        target: `${parseBalance(
            hardcap,
            payment_decimals,
            2,
            true,
        )} ${payment_symbol}`,
        percentage: percent_filled,
    };

    const is_token_claim = event_type === EventType.TokenClaim;

    useEffect(() => {
        if (!is_token_claim || finalized) return;
        const handler = setInterval(getWhitelistSummaryForUser, 5000);
        return () => clearInterval(handler);
    }, [finalized, getWhitelistSummaryForUser, loadProjects]);

    const is_hardcap_met = useMemo(
        () => isHardcapMet(payment_decimals, hardcap, total_raised),
        [payment_decimals, hardcap, total_raised],
    );

    const token_claim_stage = useTokenClaimEventStage(event);
    const crowdfunding_stage = useCrowdfundingEventStage(event, is_hardcap_met);

    const crowdfunding_chips = useMemo(() => {
        return getChips(crowdfunding_type, event_status);
    }, [crowdfunding_type, event_status, crowdfunding_stage, ga_registered]);

    const token_claim_chips = useMemo(() => {
        return getChips(event_type, event_status);
    }, [event_type, event_status, token_claim_stage, ga_registered]);

    const has_refund_deadline =
        !!project?.events[0]?.refund_deadline &&
        project?.events[0]?.refund_deadline > 0;

    const props_same = {
        stage: is_token_claim ? token_claim_stage : crowdfunding_stage,
        title: project_name,
        image: `${BASE_IMAGE_URL}/${id}/${hero}`,
        description: description,
        company_name: CONTENT.companyName,
        company_logo: CONTENT.logo.mobile,
        saft_url,
        terms_url: CONTENT.termsAndConditions,
        network_info: {
            logo: NETWORKS[chainId || DEFAULT_CHAIN_ID]?.network_logo,
            name: NETWORKS[chainId || DEFAULT_CHAIN_ID]?.network_name,
        },
        chips: is_token_claim ? token_claim_chips : crowdfunding_chips,
        protected_logo: has_refund_deadline
            ? 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Protected-Green.svg'
            : '',
        project_type: project?.project_type,
    };

    const event_slug = generateSlug(event_name || '');

    const end_date = useMemo(() => getEventEndDate(event), [event]);

    const vesting_start = vesting?.start
        ? dayjs(Number(vesting.start) * 1000)
        : undefined;

    const time_left_to_vesting_start = getFormattedTimeLeft(
        useCountdown(vesting_start?.toISOString()),
    );

    const has_vesting_start_passed = vesting_start
        ? vesting_start.isBefore(dayjs())
        : false;

    const start = useMemo(() => dayjs(Number(start_date) * 1000), [start_date]);

    const has_ama_event =
        !!ama_event &&
        !!ama_event?.type &&
        !!ama_event?.title &&
        !!ama_event?.start_date &&
        !!ama_event?.end_date;

    const { cutoff, ga_round_end_time, is_ga_round_over } = useEventDates(
        start,
        durations,
        event_type,
    );

    const has_correct_tier = user ? _userTier?.id >= min_tier : false;

    const can_whitelist =
        user &&
        !ga_registered &&
        has_correct_tier &&
        has_verified_identity &&
        has_verified_wallet &&
        !has_pending_verification_status;

    const time_left_to_ga_end = getFormattedTimeLeft(
        useCountdown(ga_round_end_time.toISOString()),
    );

    const time_to_event_end = getFormattedTimeLeft(
        useCountdown(end_date.toISOString()),
    );

    // update project data when the hardcap is met
    useEffect(() => {
        if (is_hardcap_met) loadProjects();
    }, [is_hardcap_met, loadProjects]);

    useEffect(() => {
        getTokenPrice(token_symbol).then((price) => setApiTokenPrice(price));
    }, [token_symbol]);

    // set up timers for the updates
    useUpdateTimers(cutoff, start, end_date, loadProjects);

    if (event_type === EventType.Crowdfunding) {
        switch (crowdfunding_stage) {
            case EventStage.PreWhitelist:
                return getCrowfundingPreWhitelistProps(
                    has_ama_event,
                    marketing_event,
                    ama_event,
                    social_platforms,
                    total_allocation,
                    chainId,
                    project_slug,
                    event_slug,
                    payment_symbol,
                    router,
                    props_same,
                );
            case EventStage.WhitelistOpen:
                return getCrowdfundingWhitelistOpenProps(
                    cutoff,
                    token_price,
                    total_allocation,
                    chainId,
                    registerForWhitelist,
                    event_id,
                    loadProjects,
                    router,
                    project_slug,
                    event_slug,
                    whitelist_loading,
                    can_whitelist,
                    ga_registered,
                    user,
                    social_platforms,
                    props_same,
                );
            case EventStage.SnapshotPeriod:
                return getCrowdfundingSnapshotProps(
                    social_platforms,
                    total_allocation,
                    chainId,
                    project_slug,
                    event_slug,
                    router,
                    props_same,
                    start,
                    num_whitelisted,
                );
            case EventStage.GaFcfsOpen:
                return getCrowdfundingGAFCFSProps(
                    social_platforms,
                    total_allocation,
                    num_whitelisted,
                    user_allocation,
                    raised_stats,
                    chainId,
                    project_slug,
                    event_slug,
                    router,
                    time_left_to_ga_end,
                    time_to_event_end,
                    is_ga_round_over,
                    props_same,
                    user,
                );

            case EventStage.Complete:
                return getCrowdfundingCompleteProps(
                    num_whitelisted,
                    raised_stats,
                    project_slug,
                    event_slug,
                    social_platforms,
                    props_same,
                    refund_deadline,
                    user,
                    user_investment,
                    payment_decimals,
                    payment_symbol,
                    vesting_start,
                    has_vesting_start_passed,
                    time_left_to_vesting_start,
                    router,
                );
            default:
                break;
        }
    } else {
        switch (token_claim_stage) {
            case EventStage.PreWhitelist:
                return getTokenClaimPreWhitelistProps(
                    total_allocation,
                    chainId,
                    has_ama_event,
                    ama_event,
                    marketing_event,
                    social_platforms,
                    project_slug,
                    event_slug,
                    router,
                    props_same,
                );
            case EventStage.WhitelistOpen:
                return getTokenClaimWhitelistOpenProps(
                    end_date,
                    total_allocation,
                    chainId,
                    social_platforms,
                    project_slug,
                    event_slug,
                    router,
                    props_same,
                    user,
                    ga_registered,
                    api_token_price,
                );
            case EventStage.Complete:
                return getTokenClaimCompleteProps(
                    total_allocation,
                    chainId,
                    social_platforms,
                    project_slug,
                    event_slug,
                    router,
                    props_same,
                    user,
                    has_claimed,
                    alloc_to_use,
                    token_symbol,
                    token_decimals,
                    account,
                    connect,
                    connectedChain,
                    setChainID,
                    is_connected_verified_wallet,
                    settingChain,
                    finalized,
                    loading,
                    uses_merkle_root,
                    token_claim,
                    proof,
                    update,
                    num_whitelisted,
                    ga_registered,
                    time_left_to_vesting_start,
                    has_vesting_start_passed,
                    setLoading,
                    api_token_price,
                );

            default:
                break;
        }
    }
};
